import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import axios from "axios";
import "@/assets/scss/app.scss";
// import {isCPF, isCNPJ} from 'validation-br'
axios.defaults.baseURL = 'https://us-central1-vindi-pagamento.cloudfunctions.net/';

import { initFirebaseBackend } from './helpers/firebase/authUtils';
const firebaseConfig = {
    apiKey: "AIzaSyAXFhqUqRItdhmvMkGCeLPPrze61MRpkyg",
    authDomain: "vindi-pagamento.firebaseapp.com",
    projectId: "vindi-pagamento",
    storageBucket: "vindi-pagamento.appspot.com",
    messagingSenderId: "131375112186",
    appId: "1:131375112186:web:ac1ca0c72b99856825ce43",
    measurementId: "G-C0X69YNW0H"
};


initFirebaseBackend(firebaseConfig);


// Vue.filter('doc', function (value) {
//   if (isCPF(value)) {
//
//   }else if (isCNPJ(value)) {
//
//   }
//   return value;
// })

// Vue.use(axios);
Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(VueTheMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
